.button {
    cursor: pointer;
    font: inherit;
    border: none;
    font-size: 3.5vw;
    background-color: #024712;
    color: white;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    font-weight: bold;
    transition: all 0.3s;
}
@media (min-width:600px) {
    .button {
        cursor: pointer;
        font: inherit;
        border: none;
        font-size: 1.6vw;
        background-color: #024712;
        color: white;
        padding: 0.75rem 1rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 25px;
        font-weight: bold;
        transition: all 0.3s;
    }
}

@media (min-width:900px) {
    .button {
        cursor: pointer;
        font: inherit;
        border: none;
        font-size: 16;
        background-color: #024712;
        color: white;
        padding: 0.75rem 1rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 25px;
        font-weight: bold;
        transition: all 0.3s;
    }
}

.button:hover,
.button:active {
    background-color: #002c0a;
    transform: translateY(-0.2rem) scale(1.03);
    box-shadow: 1rem 1.5rem 4rem rgba(rgb(39, 38, 38), 0.15);
}

.icon {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
}

.badge {
    background-color: #b94517;
    padding: 0.25rem 1rem;
    border-radius: 25px;
    margin-left: 1rem;
    font-weight: bold;
}

.button:hover .badge,
.button:active .badge {
    background-color: #92320c;
}

.bump {
    animation: bump 300ms ease-out;
}

@keyframes bump {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(0.9);
    }
    30% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
