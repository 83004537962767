.header {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 2.4vw;
    width: 100%;
    height: 4rem;
    background-color: #027c2d;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 10;
}
@media (min-width:600px) {
    .header {
        position: fixed;
        top: 0;
        left: 0;
        font-size: 1.8vw;
        width: 100%;
        height: 5rem;
        background-color: #027c2d;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5%;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        z-index: 10;
    }
}

@media (min-width:900px) {
    .header {
        position: fixed;
        top: 0;
        left: 0;
        font-size: 1.4vw;
        width: 100%;
        height: 5rem;
        background-color: #027c2d;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5%;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        z-index: 10;
    }
}

.main-image {
    width: 100%;
    height: 25rem;
    z-index: 0;
    overflow: hidden;
}

.main-image img {
    width: 110%;
    height: 100%;
    object-fit: cover;
    transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
}
