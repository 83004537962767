@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #3f3f3f;
}


.Modal_backdrop__1mfF6 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.Modal_modal__4DXUe {
  position: fixed;
  top: 10vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: Modal_slide-down__W3MdX 300ms ease-out forwards;
}

@media (min-width: 768px) {
  .Modal_modal__4DXUe {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes Modal_slide-down__W3MdX {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Cart_cart-items__2pjQw {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 35vh;
  overflow: auto;
}

.Cart_total__3pLin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.Cart_actions__1GOmr {
  text-align: right;
}

.Cart_actions__1GOmr button {
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #8a2b06;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  margin-left: 1rem;
}

.Cart_actions__1GOmr button:hover,
.Cart_actions__1GOmr button:active {
  background-color: #5a1a01;
  border-color: #5a1a01;
  color: white;
}

.Cart_actions__1GOmr .Cart_button--alt__1Z2QQ {
  color: #8a2b06;
}

.Cart_actions__1GOmr .Cart_button__fw1-t {
  background-color: #8a2b06;
  color: white;
}

.CartItem_cart-item__1B1_q {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #8a2b06;
  padding: 1rem 0;
  margin: 1rem 0;
}

.CartItem_cart-item__1B1_q h2 {
  margin: 0 0 0.5rem 0;
  color: #363636;
}

.CartItem_summary__3FMDj {
  width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CartItem_price__2Nl0j {
  font-weight: bold;
  color: #8a2b06;
}

.CartItem_amount__jUKEC {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  color: #363636;
}

.CartItem_actions__UwVPJ {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .CartItem_actions__UwVPJ {
    flex-direction: row;
  }
}

.CartItem_cart-item__1B1_q button {
  font: inherit;
  font-weight: bold;
  font-size: 1.25rem;
  color: #8a2b06;
  border: 1px solid #8a2b06;
  width: 3rem;
  text-align: center;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  margin-left: 1rem;
  margin: 0.25rem;
}

.CartItem_cart-item__1B1_q button:hover,
.CartItem_cart-item__1B1_q button:active {
  background-color: #8a2b06;
  color: white;
}
.Checkout_form__26gr1 {
  margin: 1rem 0;
  height: 35vh;
  overflow: auto;
}

.Checkout_control__2qGfb {
  margin-bottom: 0.5rem;
}

.Checkout_control__2qGfb label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  display: block;
}

.Checkout_control__2qGfb input {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 20rem;
  max-width: 100%;
}

.Checkout_actions__2X_6_ {
  display: flex;
  justify-content: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
}

.Checkout_actions__2X_6_ button {
  font: inherit;
  color: #5a1a01;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 25px;
  padding: 0.5rem 2rem;
}

.Checkout_actions__2X_6_ button:hover,
.Checkout_actions__2X_6_ button:active {
  background-color: #ffe6dc;
}

.Checkout_actions__2X_6_ .Checkout_submit__2BFZZ {
  border: 1px solid #5a1a01;
  background-color: #5a1a01;
  color: white;
}

.Checkout_actions__2X_6_ .Checkout_submit__2BFZZ:hover,
.Checkout_actions__2X_6_ .Checkout_submit__2BFZZ:active {
  background-color: #7a2706;
}

.Checkout_invalid__2gAN9 label {
  color: #ca3e51;
}

.Checkout_invalid__2gAN9 input {
  border-color: #aa0b20;
  background-color: #ffeff1;
}

.HeaderCartButton_button__3CMOT {
    cursor: pointer;
    font: inherit;
    border: none;
    font-size: 3.5vw;
    background-color: #024712;
    color: white;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    font-weight: bold;
    transition: all 0.3s;
}
@media (min-width:600px) {
    .HeaderCartButton_button__3CMOT {
        cursor: pointer;
        font: inherit;
        border: none;
        font-size: 1.6vw;
        background-color: #024712;
        color: white;
        padding: 0.75rem 1rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 25px;
        font-weight: bold;
        transition: all 0.3s;
    }
}

@media (min-width:900px) {
    .HeaderCartButton_button__3CMOT {
        cursor: pointer;
        font: inherit;
        border: none;
        font-size: 16;
        background-color: #024712;
        color: white;
        padding: 0.75rem 1rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 25px;
        font-weight: bold;
        transition: all 0.3s;
    }
}

.HeaderCartButton_button__3CMOT:hover,
.HeaderCartButton_button__3CMOT:active {
    background-color: #002c0a;
    transform: translateY(-0.2rem) scale(1.03);
    box-shadow: 1rem 1.5rem 4rem rgba(rgb(39, 38, 38), 0.15);
}

.HeaderCartButton_icon__9KWtg {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
}

.HeaderCartButton_badge__1Z3gL {
    background-color: #b94517;
    padding: 0.25rem 1rem;
    border-radius: 25px;
    margin-left: 1rem;
    font-weight: bold;
}

.HeaderCartButton_button__3CMOT:hover .HeaderCartButton_badge__1Z3gL,
.HeaderCartButton_button__3CMOT:active .HeaderCartButton_badge__1Z3gL {
    background-color: #92320c;
}

.HeaderCartButton_bump__3atD4 {
    animation: HeaderCartButton_bump__3atD4 300ms ease-out;
}

@keyframes HeaderCartButton_bump__3atD4 {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(0.9);
    }
    30% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

.Header_header__zSTUo {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 2.4vw;
    width: 100%;
    height: 4rem;
    background-color: #027c2d;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 10;
}
@media (min-width:600px) {
    .Header_header__zSTUo {
        position: fixed;
        top: 0;
        left: 0;
        font-size: 1.8vw;
        width: 100%;
        height: 5rem;
        background-color: #027c2d;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5%;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        z-index: 10;
    }
}

@media (min-width:900px) {
    .Header_header__zSTUo {
        position: fixed;
        top: 0;
        left: 0;
        font-size: 1.4vw;
        width: 100%;
        height: 5rem;
        background-color: #027c2d;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5%;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        z-index: 10;
    }
}

.Header_main-image__2Q3So {
    width: 100%;
    height: 25rem;
    z-index: 0;
    overflow: hidden;
}

.Header_main-image__2Q3So img {
    width: 110%;
    height: 100%;
    object-fit: cover;
    transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
}

.MealsSummary_summary__22zUX {
  text-align: center;
  max-width: 45rem;
  width: 90%;
  margin: auto;
  font-size: 1.0rem;
  margin-top: -10rem;
  position: relative;
  background-color: #383838;
  color: white;
  border-radius: 14px;
  padding: 1rem;
  box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
}

.MealsSummary_summary__22zUX h2 {
  font-size: 6vw;
  margin-top: 0;
}

@media (min-width:600px) {

.MealsSummary_summary__22zUX h2 {
  font-size: 2rem;
  margin-top: 0;
}
  .MealsSummary_summary__22zUX {
    text-align: center;
    max-width: 45rem;
    width: 90%;
    margin: auto;
    font-size: 1.2rem;
    margin-top: -10rem;
    position: relative;
    background-color: #383838;
    color: white;
    border-radius: 14px;
    padding: 1rem;
    box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
  }
}

@media (min-width:900px) {
  .MealsSummary_summary__22zUX {
    text-align: center;
    max-width: 45rem;
    width: 90%;
    margin: auto;
    font-size: 1.2rem;
    margin-top: -10rem;
    position: relative;
    background-color: #383838;
    color: white;
    border-radius: 14px;
    padding: 1rem;
    box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
  }
}


.Card_card__1m44e {
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background-color: white;
}

.AvailableMeals_meals__2ZLCP {
  max-width: 60rem;
  width: 90%;
  margin: 2rem auto;
  animation: AvailableMeals_meals-appear__ONwLi 1s ease-out forwards;
}

.AvailableMeals_mealsloading__2DBaT {
  text-align: center;
  color: #fff;
}
.AvailableMeals_mealserror__3gLh_ {
  text-align: center;
  color: red;
}

.AvailableMeals_meals__2ZLCP ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@keyframes AvailableMeals_meals-appear__ONwLi {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.MealItem_meal__2uJ70 {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.MealItem_meal__2uJ70 h3 {
  margin: 0 0 0.25rem 0;
}

.MealItem_description__2YINS {
  font-style: italic;
}

.MealItem_price__3sNYl {
  margin-top: 0.25rem;
  font-weight: bold;
  color: #ad5502;
  font-size: 1.25rem;
}
.Input_input__2swPp {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.Input_input__2swPp label {
  font-weight: bold;
  margin-right: 1rem;
}

.Input_input__2swPp input {
  width: 3rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font: inherit;
  padding-left: 0.5rem;
}

.MealItemForm_form__32qVd {
  text-align: right;
}

.MealItemForm_form__32qVd button {
  font: inherit;
  cursor: pointer;
  background-color: #068a0d;
  border: 1px solid #8a2b06;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 20px;
  font-weight: bold;
}

.MealItemForm_form__32qVd button:hover,
.MealItemForm_form__32qVd button:active {
  background-color: #641e03;
  border-color: #641e03;
}
